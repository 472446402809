.profile-container {
  position: absolute;
  top: 60%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-left: 5%;
  align-items: center;
}

.profile-img {
  width: 200px;
  height: 200px;
  object-fit: cover;
}

@media (max-width: 576px) {
  .profile-container {
    top: 6%;
    width: 100%;
    display: block;
    gap: 30px;
    padding-left: 5%;
    align-items: center;
  }

  .profile-img {
    width: 170px;
    height: 170px;
  }
}
