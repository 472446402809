@use "../../colors" as *;
@use "../Buttons/authentication" as *;




.navbar-default {
  background-color: #FEFEFF !important;
  border: 1px solid #FEFEFF !important;

  box-shadow: 0px 1px 20px 0px rgba(0, 0, 0, 0.25) !important;

}

.navbar-special {
  background-color: #CFCFCF !important;
}

.guest-navbar {
  width: 100%;


  .nav-link {
    color: $prytextcolor !important;
    display: inline-block;
    font-size: 20px;
    font-weight: 400;
  }

  .short-links .nav-link:hover,
  .active {
    background-color: $secbtncolor !important;
    color: white !important;
    padding: 0.5rem 1rem;
    border-radius: 10px;

  }



  .contact-btn {
    top: 93% !important;
    left: 40% !important;
    z-index: 1 !important;

    .btn-primary {
      background-color: $secbtncolor !important;
      color: white !important;
      border-radius: 5px !important;
      border: none !important;
      padding: 20px;
      font-size: 20px;
    }
  }

  .btn-group {
    gap: 2px;
    background-color: $secbtncolor;
  }

  .sign-btn {
    top: 93% !important;
    left: 78% !important;
    z-index: 1 !important;

    .btn-primary {
      background-color: $secbtncolor !important;
      color: white !important;
      border-radius: 5px !important;
      border: none !important;
      width: auto !important;
      padding: 20px !important;
      font-size: 20px !important;
    }
  }

  @media (max-width:992px) {

    .contact-btn {
      top: 46.5% !important;
      left: 25% !important;

      .btn-primary {
        padding: 0.6rem 1rem;
        font-size: 18px;

      }

    }

    .sign-btn {
      top: 85% !important;
      left: 25% !important;

      .btn-primary {
        padding: 0.8rem 1rem;
        font-size: 18px;

      }
    }
  }

  @media (max-width:576px) {

    .short-links .nav-link:hover {
      background-color: $secbtncolor !important;
      color: white !important;
      padding: 0.5rem 0.5rem;
      border-radius: 10px;

    }

    .contact-btn {
      top: 47% !important;
      left: 30% !important;


      .btn-primary {
        padding: 0.6rem 0.35rem;
        font-size: 16px;

      }

    }

    .sign-btn {

      left: 33% !important;

      .btn-primary {
        padding: 0.8rem 0.35rem;
        font-size: 16px;

      }
    }
  }

}