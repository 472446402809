@use "../../colors" as *;

.navBtn {
  .btn {
    height: 55px !important;
    width: 100px !important;
    border-radius: 10px !important;
  }

  .btn-outline-primary {
    border: 2px solid $secbtncolor !important;
    color: $prytextcolor !important;
    background-color: transparent !important;
  }

  .btn-primary {
    background-color: $secbtncolor !important;
    border: none !important;
  }

  .btnNewText {
    font-size: 20px !important;
    font-weight: 400 !important;
  }
}

.btn-primary {
  background-color: $secbtncolor !important;
  color: $btntextcolor !important;
  border: none;
}

.btn-outline-primary {
  background-color: $btntextcolor !important;
  color: $secbtncolor !important;
  border: 1px solid $secbtncolor !important;
}

.btn-primary:hover {
  background-color: $btntextcolor !important;
  color: $secbtncolor !important;
  border: 1px solid $secbtncolor !important;
}

.btn-outline-primary:hover,
.btn-outline-secondary:hover {
  background-color: $secbtncolor !important;
  color: $btntextcolor !important;
}