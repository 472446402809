@use "../../colors" as *;
@use "../../font" as *;


.product-card {
    .card {
        transition: transform 0.2s, box-shadow 0.2s;

        &:hover {


            transform: scale(1.02) !important;
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2) !important;

        }
    }


}