@use "../../components/Navbar/guestnav" as *;
@use "../../colors" as *;
@use "../../components/footer" as *;
@use "../../components/buttons" as *;


.landing-hero-card {


  .btn-primary {
    background-color: $secbtncolor;
    border: $secbtncolor;
    font-size: 20px;
    font-weight: 400;
    padding: 13px 40px;
  }
}

.services-section,
.choose-us-section {

  .btn-outline-primary {
    border: 1px solid $secbtncolor !important;
    color: $prytextcolor;
    font-size: 20px;
    font-weight: 400;
    padding: 13px 40px;
  }

  .btn-primary {
    background-color: $secbtncolor;
    border: $secbtncolor;
    font-size: 20px;
    font-weight: 400;
    padding: 13px 40px;
  }

  .card {
    border-radius: 16px;
    border: none;
    box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.16);
  }

  .carousel-control-prev {
    position: absolute;
    left: -3%;
  }

  .carousel-control-next {
    position: absolute;
    right: -3%;
  }

  .carousel-control-prev-icon-bg,
  .carousel-control-next-icon-bg {
    fill: black !important;
  }
}

.works-section {

  .btn-outline-primary {
    border: 1px solid $secbtncolor;
    font-size: 20px;
    color: black;
  }

  .active-btn-outline {
    background-color: $secbtncolor !important;
    color: $btntextcolor !important;
  }
}

.line {
  border-bottom: 0.3px solid black;
}

.in-touch-card {


  .btn-outline-light {
    padding: 13px 45px;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .landing-contact-btn {
    top: 100%;
    left: 38%;

    z-index: 1;

    .btn-primary {
      background-color: $secbtncolor !important;
      color: white !important;
      border-radius: 5px !important;
      border: none !important;
      padding: 20px;
      font-size: 20px;
    }
  }

  .btn-group {
    gap: 2px;
    background-color: $secbtncolor;
  }
}

@media (max-width: 992px) {
  .choose-us-section {
    .carousel-control-prev {
      left: -9%;
    }

    .carousel-control-next {
      right: -9%;
    }
  }
}

@media (max-width: 768px) {
  .landing-hero-card {
    .card-title {
      font-size: 28px !important;
      line-height: 40px !important;
    }

  }

  .services-section,
  .choose-us-section {


    .carousel-control-prev {
      left: -4%;
    }

    .carousel-control-next {
      right: -4%;
    }
  }

  .in-touch-card {

    .landing-contact-btn {
      left: 17%;
    }
  }

  .works-section {

    h4 {
      font-size: 22px !important;
    }

  }

  .btn-outline-primary,
  .btn-primary,
  .btn-outline-light,
  .btn-outline-dark {
    font-size: 18px;
  }
}

@media (max-width: 576px) {
  .in-touch-card {
    .landing-contact-btn {
      left: 5%;

      .btn-primary {
        padding: 10px;
      }
    }
  }
}