@use "../../colors" as *;
@use "../../font" as *;

.pre-cart {
    .progress {
        border-radius: 0 !important;



    }


}

.product-carousel-container {
    .carousel-control-prev {
        position: absolute;
        left: -10%;
    }

    .carousel-control-next {
        position: absolute;
        right: -10%;
    }
}

.img-fixed-height {
    height: 11rem !important;

}

@media (max-width: 576px) {
    .product-carousel-container {
        .carousel-control-prev {
            position: absolute;
            left: -2%;
        }

        .carousel-control-next {
            position: absolute;
            right: -2%;
        }
    }
}