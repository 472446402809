@use "../colors" as *;
@use "../font" as *;



.buttonStyle {
  background-color: $btncolor;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  width: 10rem;
  height: 3rem;

  &:hover {
    background-color: $btncolor;
    color: $btntextcolor;
  }
}

.btnText {
  font-size: 1.1rem;
  font-weight: 500;
  font-family: "Raleway", sans-serif;
}