@use "../../components/Navbar/guestnav" as *;
@use "../../colors" as *;
@use "../../components/footer" as *;
@use "../../components/buttons" as *;



.question-section {

  .btn-outline-primary {
    font-size: 20px;
    padding: 10px 40px;
    border: 2px solid $secbtncolor !important;
    color: $prytextcolor;
  }
}


@media (max-width: 992px) {
  .team-section {
    p {
      font-size: 20px;
    }
  }
}

@media (max-width: 768px) {

  .question-section {
    h1 {
      font-size: 26px !important;
    }

    .legal-image {
      width: 40%;
    }
  }

  .team-section {
    p {
      font-size: 16.5px !important;
    }
  }

}