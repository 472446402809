@use "../../colors" as *;
@use "../../components/Navbar/guestnav" as *;
@use "../../components/Forms/authentication"  as *;

.otp-input {
  border-radius: 10px;
  border: 1px solid #afafaf;
  background: #fefeff;
  box-shadow: 0px 1px 8px 0px rgba(79, 15, 15, 0.16);
}
