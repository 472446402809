@use "../../colors" as *;
@use "../../components/Buttons/authentication" as *;


.btn-newPrimary {
    background-color: $secbtncolor !important;
    color: white !important;
    border: none;

    &:hover {
        background-color: $secbtncolor !important;
        color: white !important;
        border: none;
    }

}

.login-card {
    color: #7E7E7F;

    .card {
        box-shadow: 0px 1px 8px 0px rgba(79, 15, 15, 0.16);
        max-width: 30rem !important;

    }



    .welcome-modal {
        border-radius: 10px !important;
        background-color: linear-gradient(0deg, rgba(70, 76, 225, 0.20) 0%, rgba(70, 76, 225, 0.20) 100%), #FEFEFF !important;
        display: inline-flex !important;
        padding: 64px !important;
        flex-direction: column !important;
        align-items: center !important;
    }

    .p-small {

        color: #7E7E7F;
        font-size: 16px;


        @media (max-width:768px) {
            font-size: 16px !important;
        }
    }

   
    .btn-group {
        gap: 2px;
        background-color: $secbtncolor;

    }

    .form-sign-btn {
        top: 90%;
        left: 15%;
        z-index: 1;

        .btn-primary {
            background-color: $secbtncolor !important;
            color: white !important;
            border-radius: 5px !important;
            border: none !important;
            width: 149px;
            padding: 18px 0px;
            font-size: 18px;
        }

    }
}